import classnames from "classnames";
import { LineItem } from "../../types";
import styles from "../Item.module.scss";

type ItemProps = {
    part: LineItem;
    kitCode: string | null;
    serialNumber: string | null;
};

export const Item = ({ part, kitCode, serialNumber }: ItemProps) => {
    const translations = window.app.preloadState.translation;
    return (
        <a className={classnames(styles.wrapper, { [styles.kidChildGroup]: kitCode })} href={part.url}>
            <div className={styles.group}>
                <img className="objectFitContain" src={(part.imageUrl ?? "") + "?width=80"} alt={part.displayName} width="80" />
                <div className={styles.infoBlock}>
                    <p className={styles.title}>{part.displayName}</p>
                    <p className={classnames(styles.articleNumber, "firstLetterCapital")}>
                        {part.code && <span className={styles.itemSubInfo}>{`${translations["orderConfirmation.articleNo"]}: ${part.code}`}</span>}
                        {kitCode && <span className={styles.itemSubInfo}>{`${translations["orderConfirmation.kitCode"]}: ${kitCode}`}</span>}
                        {serialNumber && <span className={styles.itemSubInfo}>{`${translations["orderConfirmation.serialNumber"]}: ${serialNumber}`}</span>}
                    </p>
                    {part.labels?.length > 0 &&
                        <p className={classnames(styles.labels)}>
                            {part.labels.map(label =>
                                <span key={label.text} className={styles.label}>{label.text}</span>
                            )}
                        </p>
                    }
                    <p className={styles.additionalInfo}>
                        <span className={"firstLetterCapital"}>{translations["orderConfirmation.quantityFrp"]}: {part.inventory?.packageQuantity ?? 0}</span>
                        {!!part.unitId && <span className={"firstLetterCapital"}>{translations["orderConfirmation.unit"]}: {part.unitId}</span>}
                    </p>
                </div>
            </div>
            <div className={styles.prices}>
                {part.hasDiscount && (
                    <div className="d-md-none">
                        <p className={styles.discountedPrice}>{part.unitDiscountedPrice}</p>
                    </div>
                )}
                <div>
                    <div className={classnames(styles.md, "firstLetterCapital")}>{translations["orderConfirmation.price"]}:</div>
                    <p
                        className={classnames({
                            [styles.discountedPrice]: !part.hasDiscount,
                            [styles.originalPrice]: part.hasDiscount,
                            [styles.strike]: part.hasDiscount,
                        }, "d-md-none")}
                    >
                        {part.unitBasePrice}
                    </p>
                    <p
                        className={classnames(styles.sum, "d-none d-md-inline")}
                    >
                        {part.unitDiscountedPrice}
                    </p>
                </div>
                {part.hasDiscount && (
                    <div>
                        <div className="firstLetterCapital d-inline-block">{translations["orderConfirmation.discount"]}:</div>
                        <span>
                            <span className={classnames("d-none d-md-inline m-right--x3", styles.strike, styles.originalPrice)}>{part.unitBasePrice}</span>
                            <span className={classnames(styles.discount, "d-inline-block")}>-{part.discountPercentage} %</span>
                        </span>
                    </div>
                )}
            </div>
            <div className={styles.quantity}>
                <div className={classnames(styles.md, "firstLetterCapital")}>{translations["orderConfirmation.quantity"]}:</div>
                {part.quantity}
            </div>
            <div className={styles.sumWrapper}>
                <div className={classnames(styles.md, "firstLetterCapital")}>{translations["orderConfirmation.total"]}:</div>
                <p className={styles.sum}>{part.totalPrice}</p>
            </div>
        </a>
    );
};
